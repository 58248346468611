<template>
  <v-card class="my-3 px-2">
    <v-card-title>
      <span>PDV Vouchers:</span>
    </v-card-title>
    <v-card-text class="pa-0">
      <v-row no-gutters>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <div v-bind="attrs" v-on="on">
              <v-switch v-model="disableVouchers" class="ml-4 mr-6 mt-2" label="Desabilitar vouchers PDV" />
            </div>
          </template>
          <span>* Ativo = Desabilita, Inativo = Habilita</span>
        </v-tooltip>
      </v-row>
    </v-card-text>
    <v-card-actions class="py-6 px-4">
      <v-spacer />
      <v-btn width="260" color="primary" :loading="actionLoading" @click="openDialog"><v-icon class="mr-1">sync</v-icon> Atualizar PDV Vouchers</v-btn>
    </v-card-actions>
    <confirmation-dialog :dialog.sync="dialog" :text="textDialog" @proceed="updateDisableVouchers" @cancel="closeDialog" />
  </v-card>
</template>

<script>
import { MUTATION_UPDATE_PDV_VOUCHERS } from '@/modules/accounts/graphql'

export default {
  components: {
    ConfirmationDialog: () => import('@/components/base/ConfirmationDialog')
  },
  props: {
    accountId: {
      type: String,
      default: ''
    },
    account: {
      type: Object,
      default: () => ({})
    }
  },
  data: () => ({
    lazyValue: false,
    dialog: false,
    textDialog: '',
    actionLoading: false
  }),
  computed: {
    disableVouchers: {
      get() {
        const disablePdvVouchers = this.account?.channels?.pdv?.disable_vouchers
        return disablePdvVouchers !== undefined ? disablePdvVouchers : this.lazyValue
      },
      set(value) {
        this.lazyValue = value
      }
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false
      this.text = ''
    },
    openDialog() {
      this.textDialog = 'Atualizar o status de PDV vouchers sobrescreve a configuração anterior. Tem certeza que deseja salvar?'
      this.dialog = true
    },
    async updateDisableVouchers() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_UPDATE_PDV_VOUCHERS,
          variables: {
            account_id: this.accountId,
            value: this.lazyValue
          }
        })
        this.$emit('refresh')
        this.$snackbar({ message: 'Status de PDV vouchers atualizados', snackbarColor: '#2E7D32' })
      } catch (err) {
        console.log(err?.message)
        this.$snackbar({ message: 'Falha ao atualizar status de PDV vouchers', snackbarColor: '#F44336' })
      }
      this.closeDialog()
    }
  }
}
</script>
